/* eslint-disable complexity */
/* eslint-disable no-debugger */
/* eslint-disable max-statements */
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-js-pagination";
import {  CURRENCY, FILTER_ENTITIES, LISTING_TYPE, NUMBER, RECENTLY_APPLIED_FILTERS } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import styles from "./styles.css";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import FilterPills from "../filter-pills/component";
import ClockIcon from "./images/update.svg";
import StarIcon from "./images/star.svg";
import { useHistory } from "react-router";
import { API_VERSION_V2 } from "../../../service/api-version";
import parseAutoSuggestResponse from "../../../utils/filters-v2/parse-auto-suggest-response";
import debounce from "../../../utils/helpers/debounce";
import parseCookie from "../../../utils/helpers/parse-cookie";
import saveCookie from "../../../utils/helpers/save-cookie";
import getUpdatedAppliedFilters from "../../../utils/filters-v2/get-updated-applied-filters";
import { getRecentSearch, updateSearchList } from "../../shared/global-search-au/selectors";
import getHighlightedText from "../../../utils/helpers/get-highlighted-text";
import { APPLIED_FILTER_META } from "../../../utils/filters-v2/constants";
import priceFormatter from "../../../utils/helpers/price-formatter";
import Button from "../../shared/button";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import useStyleInjection from "../../../hooks/use-style-injection";
import {yieldToMain} from "../../../utils/helpers/yield-to-main";

const HIDDEN_FILTERS = [FILTER_ENTITIES.GREAT_DEALS];

const CUSTOM_SELECT_FILTER_PILL_NAMES = {
    TEST_DRIVE: "Test Drive",
    SALE: "On Sale"
};

const SearchRevamp = ({
    secureToken = "",
    fetchAutoSuggestionsConnect,
    updateFilterOptionDataConnect,
    fetchSearchResultConnect,
    searchPageResult,
    selectedCity = {},
    allFilters,
    selectedFilters,
    clearAllFiltersConnect,
    updateUrlOptionsConnect,
    reloadCarListConnect,
    urlOptions,
    appliedFilters,
    updateSelectedSuggestionsConnect,
    updateListingTypeConnect,
    updateAppliedFiltersConnect,
    email,
    clearSelectedFilterConnect,
    isHomePage = false,
    totalAvailableCars,
    saleConfig = {},
    webPriceDropDesktop
    // updateAllSelectedFilterOptionsConnect,
    // setListingFBEventsConnect
}) => {
    const history = useHistory();
    const isFromHomePage = (history.location.state || {}).isFromHomePage;
    const searchRef = useRef();
    const { code: selectedCityCode } = selectedCity || {};
    const [searchInput, setsearchInput] = useState(urlOptions.search);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [noResult, setNoResult] = useState(false);
    const {saleLive} = saleConfig?.data || {};
    // const [isSuggestionVisible, setIsSuggestionVisible] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [configUrl, setConfigUrl] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [loader, setLoader] = useState(false);
    const [searchSection, showSearchSection] = useState(false);
    const [showAllSelectedFilter, setShowAllSelectedFilter] = useState(false);

    const { popularSearchFilters = [], personalizedFilters = [] } = searchPageResult || {};
    const inputSearchValue = searchInput || urlOptions.search;
    const recentSearched = getRecentSearch() || [];

    const showWebPriceDesktop =
    getAbExpirementVariant(webPriceDropDesktop, EXPERIMENT_TYPE.VARIANT_B);
    const { getOrCreateRef, injectStyles } = useStyleInjection();

    // const lastSixAppliedFilters = personalizedFilters.reverse().slice(0, NUMBER.SIX);

    useEffect(() => {
        if (isHomePage) {
            showSearchSection(false);
            setsearchInput("");
            updateUrlOptionsConnect({ search: "" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHomePage]);

    useEffect(() => {
        fetchSearchResultConnect();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NEW_SEARCH_SECTIONS_VISIBLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken]);

    const handleClickOutside = event => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            showSearchSection(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleSearch = (data) => {
        setSearchSuggestions(data);
    };

    const triggerSuggestionGAEvent = (inputValue, suggestions) => {
        const eventLabel = suggestions.join();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.FILTERS_SEARCH_BRANDS, {
            eventLabel: `${inputValue}: ${eventLabel.length === 0 ? "No result" : eventLabel}`
        });
    };

    const getOptions = (inputValue) => {
        const payload = { search: inputValue, showAutoSuggestionVariant: true };
        setLoader(true);
        showSearchSection(true);
        fetchAutoSuggestionsConnect(payload, API_VERSION_V2).then(response => {
            const { config: responseConfig, autoSuggestions = [] } = parseAutoSuggestResponse(response);
            setConfigUrl(responseConfig);
            setLoader(false);
            let data = [];
            data = autoSuggestions.length ? autoSuggestions : searchSuggestions;

            triggerSuggestionGAEvent(inputValue, data);
            if (data && data.length > 0) {
                //save to cookie
                handleSearch(data);
                setNoResult(false);
            } else if (data.length <= 0) {
                // handleSearch([]);
                setNoResult(true);

                trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE, {
                    eventAction: "auto_suggestion_no_result",
                    eventLabel: inputValue
                });
            }
        });
        // }
    };

    const onSearchClickHandler = async (selectedValue, searchCategory, isFreeText) => {
        const { value = "", type = "", label = "" } = selectedValue || {};
        updateSearchList(selectedValue);
        showSearchSection(false);
        setsearchInput(value);
        await yieldToMain();
        const currentFilters = new Filters({ cityCode: selectedCityCode });
        const { relativeURL: listingUrl } = currentFilters.getListingPayload({
            selectedFilters, //appliedFilters,
            filters: allFilters,
            ...(value && {
                options: {
                    ...urlOptions,
                    search: value
                }
            })
        });
        updateListingTypeConnect(LISTING_TYPE.SEARCH);
        clearAllFiltersConnect();
        updateUrlOptionsConnect({ search: value });
        reloadCarListConnect(true);
        if (isHomePage) {history.push(listingUrl, {isFromHomePage: true});} else {history.replace(listingUrl);}
        window.setTimeout(() => window.scrollTo(0, 0));
        const category = type === "Make" ?
            AU_DESKTOP_EVENTS.FILTERS_SEARCH_SUGGESTED_BRANDS :
            AU_DESKTOP_EVENTS.FILTERS_SEARCH_SUGGESTED_MODELS;
        await yieldToMain();
        trackDesktopCustomEventsAU(category, { eventLabel: `options ${label || value}` });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CAR_SEARCH, {
            search_term: value,
            user_id: email
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE, {
            eventAction: "search_text",
            eventLabel: value
        });
        if (searchCategory === "recent") {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE_RECENTLY_SEARCHED, {
                eventLabel: `${selectedValue.value}`
            });
        } else if (searchCategory === "popular") {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE_POPULAR_SEARCH, {
                eventLabel: `${selectedValue.value}`
            });
        } else if (!isFreeText) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.LISTING_PAGE_SEARCH_AUTO_SUGGESTION_APPLIED, {
                eventLabel: `${selectedValue.value}`
            });
        }
    };

    const delayedQuery = useCallback(
        debounce((val) => {
            getOptions(val);
        }, NUMBER.THREE_HUNDRED),
        [searchSuggestions]);

    useEffect(() => {
        if (urlOptions.search && !isHomePage && !isFromHomePage) {
            delayedQuery(urlOptions.search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearInput = () => {
        setsearchInput("");
        setNoResult(false);
        handleSearch([]);
        updateFilterOptionDataConnect([]);
        updateUrlOptionsConnect({ search: "" });
        reloadCarListConnect(true);
        const currentFilters = new Filters({ cityCode: "" });
        let { relativeURL: listingUrl } = currentFilters.getListingPayload({
            selectedFilters: [], //appliedFilters,
            filters: allFilters
        });

        if (listingUrl?.indexOf("&showClpCrmVariant") > -1) {
            listingUrl = currentFilters.removeQueryParams(listingUrl, "showClpCrmVariant");
        }

        if (!isHomePage) {
            history.replace(listingUrl);
        }
    };

    const handleInputChange = (event) => {
        const searchInputValue = event.target.value.replace("=", "");
        // const isSpecialCharIncluded = new RegExp(/[^a-zA-Z0-9| ]/).test(searchInputValue);
        // if (isSpecialCharIncluded) { return; }
        if (searchInputValue.length > 0) {
            setsearchInput(searchInputValue);
            delayedQuery(searchInputValue);
            // setIsSuggestionVisible(false);
        } else {
            // setIsSuggestionVisible(true);
            clearInput();
        }

    };

    const clearAllFilters = async () => {
        // inject dynamic styles to element with id=clearAllFilters
        const el = document.getElementById("clearAllFilters");
        if (el) {
            el.style.opacity = "0.7";
            void el.offsetHeight;
        }
        clearAllFiltersConnect();
        clearInput();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.FILTER_REVAMP_CLEAR_ALL_FILTERS_SELECTED);
    };

    const onRemoveFilter = (item) => {
        const cleanup = injectStyles(`remove-filter-${item?.filterKey}`);
        if (item.filterKey === FILTER_ENTITIES.MODELCITYNAME) {
            clearAllFilters();
            // eslint-disable-next-line no-unused-expressions
            cleanup?.();
            return;
        }
        const { filterKey, optionKey, subOptionKey, min, max } = item || {};
        const data = {
            filterKey,
            optionKey,
            subOptionKey,
            isSelected: false,
            ...(min && { min }),
            ...(max && { max })
        };

        // REMOVE FILTER FROM COOKIE
        const lastAppliedFilters = JSON.parse(parseCookie(RECENTLY_APPLIED_FILTERS)) || [];
        const savedIndexFilter = lastAppliedFilters.findIndex((f) => f.filterKey === data.filterKey && f.optionKey === data.optionKey);
        const filteredList = personalizedFilters.reverse().filter((_, i) => savedIndexFilter !== i);
        saveCookie(RECENTLY_APPLIED_FILTERS, JSON.stringify(filteredList));

        updateFilterOptionDataConnect(data);
        updateAppliedFiltersConnect();
        reloadCarListConnect(true);
        const updatedFilters = getUpdatedAppliedFilters(data, appliedFilters);
        if (updatedFilters.length === NUMBER.ONE) {
            if (updatedFilters[0].filterKey === FILTER_ENTITIES.MODELCITYNAME) {
                updatedFilters[0].filterKey = FILTER_ENTITIES.MAKE;
            }
        }
        const filters = new Filters({ cityCode: "" });
        const { relativeURL } = filters.getListingPayload({
            selectedFilters: updatedFilters, filters: allFilters,
            ...(urlOptions.search && item.type !== "search" && { options: { search: urlOptions.search } })
        });
        if (item.type === "search") {
            updateUrlOptionsConnect({ search: "" });
        }
        if (optionKey) {
            clearSelectedFilterConnect(optionKey.toLowerCase());
        }

        updateSelectedSuggestionsConnect(data);
        history.replace(relativeURL);
    };

    const handleFocus = () => {
        showSearchSection(true);

    };

    // const handleBlur = () => {
    //     showSearchSection(false);
    // };

    const handleShowAllSelectedFilter = () => {
        setShowAllSelectedFilter(!showAllSelectedFilter);
    };

    const handleOnKeyDown = (event) => {
        if (event.keyCode === NUMBER.THIRTEEN || event.which === NUMBER.THIRTEEN  &&  searchInput && searchInput.trim() !== "") {
            const isFreeText = true;
            onSearchClickHandler({ value: event.target.value }, null, isFreeText);
        }

    };

    const onShowButtonClickHandler = () => {
        const isFreeText = true;
        onSearchClickHandler({ value: searchInput }, null, isFreeText);
    };

    const getSelectedFilterTag = ({ filterKey, isRangeFilter, displayText, optionKey, subOptionKey, min, max }) => {

        const displayTag = `${optionKey} ${subOptionKey || ""}`;

        const suffix = APPLIED_FILTER_META[filterKey] && APPLIED_FILTER_META[filterKey].suffix;
        const prefix = APPLIED_FILTER_META[filterKey] && APPLIED_FILTER_META[filterKey].prefix;

        let displayMin = min;
        let displayMax = max;

        if (prefix === CURRENCY.AU || suffix === "KMs") {
            displayMin = priceFormatter(min, true, CURRENCY.AU);
            displayMax = priceFormatter(max, true, CURRENCY.AU);
        }

        if (filterKey === "imperfectionCount") {
            return `Imperfections: ${displayTag}`;
        } else if (filterKey === FILTER_ENTITIES.SEATS) {
            return `${displayTag} Seats`;
        } else if (filterKey === FILTER_ENTITIES.SAFETYANDQUALITY) {
            return `ANCAP: ${displayTag}`;
        } else if (filterKey === FILTER_ENTITIES.TEST_DRIVE) {
            return CUSTOM_SELECT_FILTER_PILL_NAMES.TEST_DRIVE;
        } else if (isRangeFilter) {
            return displayText ? displayText :
                `${prefix ? `${prefix} ` : ``}${displayMin}${suffix ? ` ${suffix}` : ``} - ${prefix ? `${prefix} ` : ``}${displayMax}${suffix ? ` ${suffix}` : ``}`;
        } else if (filterKey === FILTER_ENTITIES.GREAT_DEALS) {
            const text = displayText.split("_").join(" ");
            return text;
        }
        return displayTag;
    };

    const selectedFiltersModified = showWebPriceDesktop ? selectedFilters : selectedFilters.filter((item) => !HIDDEN_FILTERS.includes(item.filterKey));

    const searchSuggestionsLabels = searchSuggestions.length > 0 ? searchSuggestions.map(item => item.label) : [];

    const selectedFilterPills = showAllSelectedFilter ? selectedFiltersModified : selectedFiltersModified.slice(0, NUMBER.SIX);
    const popularSearch = (popularSearchFilters || {}).result || [];
    const modifiedSearch = recentSearched && recentSearched.slice(0, NUMBER.SIX);
    const searchWrapperStyle = isHomePage ? "styles.homeSearchWrapper" : "styles.searchWrapper";
    return (
        <React.Fragment>
            <div styleName={"styles.outer"} ref={searchRef}>
                <div styleName={searchWrapperStyle}>
                    <input type="text"
                        placeholder="Search by make/model"
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        // onBlur={handleBlur}
                        value={inputSearchValue}
                        onKeyPress={handleOnKeyDown}
                    />
                    {isHomePage && <Button text={`view all ${totalAvailableCars || "" } cars`} onClick={onShowButtonClickHandler} />}
                    {searchSection &&
                        <React.Fragment>
                            {!inputSearchValue &&
                                <div styleName={isHomePage ? "styles.homepageSearchDropdownWrapper" : "styles.searchDropdownWrapper"}>
                                    {(modifiedSearch || []).length > 0 &&
                                        <div styleName={"styles.searchHeadingWrapper"}>
                                            <p styleName={"styles.searchHeading"}>Recent searches</p>
                                        </div>
                                    }
                                    {(modifiedSearch || []).map(item =>
                                        (<div styleName={"styles.searchOptionWrap"} key={item.value} onClick={onSearchClickHandler.bind(null, { value: item.value }, "recent", false)}>
                                            <div styleName={"styles.searchUpdateWrapper"}>
                                                <img src={ClockIcon} />
                                                <p styleName={"styles.searchOption"}>{item.value}</p>
                                            </div>
                                        </div>
                                        )
                                    )}
                                    {popularSearch.length > 0 && modifiedSearch.length !== NUMBER.SIX &&
                                        <React.Fragment>
                                            <div styleName={"styles.searchHeadingWrapper"}>
                                                <p styleName={"styles.searchHeading"}>Popular suggestions</p>
                                            </div>

                                            {popularSearch.slice(0, NUMBER.SIX - recentSearched.length).map(item =>
                                                (<div styleName={"styles.searchOptionWrap"} key={item} onClick={onSearchClickHandler.bind(null, { value: item }, "popular", false)}>
                                                    <div styleName={"styles.searchUpdateWrapper"}>
                                                        <img src={StarIcon} />
                                                        <p styleName={"styles.searchOption"}>{item}</p>
                                                    </div>
                                                </div>)
                                            )}
                                        </React.Fragment>
                                    }
                                </div>
                            }
                            {inputSearchValue && searchSuggestionsLabels.length > 0 &&
                                <div styleName={isHomePage ? "styles.homepageSearchDropdownWrapper" : "styles.searchDropdownWrapper"}>

                                    <div styleName={"styles.searchHeadingWrapper"}>
                                        <p styleName={"styles.searchHeading"}>Search Suggestions</p>
                                    </div>
                                    {searchSuggestionsLabels.map(item =>
                                        (<div styleName={"styles.searchOptionWrap"} key={item} onClick={onSearchClickHandler.bind(null, { value: item }, "modalSearch", false)}>
                                            <div styleName={"styles.searchUpdateWrapper"}>
                                                <p styleName={"styles.searchOption"}>{getHighlightedText(item, inputSearchValue)}</p>
                                            </div>
                                        </div>)
                                    )}
                                </div>
                            }

                        </React.Fragment>
                    }
                    {inputSearchValue && searchSuggestionsLabels.length === 0 && noResult &&
                        <div styleName={isHomePage ? "styles.homepageNoSuggestionFound" : "styles.searchHeadingWrapper"}>
                            <p styleName={"styles.searchHeading"}>No suggestions found</p>
                        </div>}
                </div>
            </div>
            {selectedFilterPills.length > 0 && !isHomePage &&
                <div className="col-12" styleName={"styles.selectedFilterWrap"}>
                    <p styleName={"styles.selectedFilter"}>Selected Filters</p>
                    <div styleName={"styles.filterWrapper"}>
                        <p id="clearAllFilters" styleName={"styles.clearFilter"} onClick={clearAllFilters}>Clear all filters</p>
                        {selectedFilterPills.map((item, index) =>
                            (item && <div ref={getOrCreateRef(`remove-filter-${item?.filterKey}`)} styleName={"styles.filterPillsWrapper"} key={index}>
                                <FilterPills
                                    filterName={getSelectedFilterTag(item)}
                                    onRemoveFilter={onRemoveFilter.bind(null, item)}
                                    defaultText = {item?.filterKey === FILTER_ENTITIES.PROMOTION && saleLive ? true : false}
                                />
                            </div>)
                        )}
                        {selectedFiltersModified.length > NUMBER.SIX &&
                            <div styleName={"styles.filterPillsWrapper"} onClick={handleShowAllSelectedFilter}>
                                <FilterPills filterName={
                                    showAllSelectedFilter ? "Show less" : `show ${selectedFiltersModified.length - NUMBER.SIX} more`}
                                showCloseIcon={false}
                                type={"white"}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

SearchRevamp.propTypes = {
    secureToken: PropTypes.string,
    fetchAutoSuggestionsConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    fetchSearchResultConnect: PropTypes.func,
    searchPageResult: PropTypes.object,
    selectedCity: PropTypes.string,
    allFilters: PropTypes.object,
    selectedFilters: PropTypes.array,
    clearAllFiltersConnect: PropTypes.func,
    updateUrlOptionsConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    urlOptions: PropTypes.object,
    appliedFilters: PropTypes.array,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateListingTypeConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    email: PropTypes.string,
    updateAllSelectedFilterOptionsConnect: PropTypes.func,
    setListingFBEventsConnect: PropTypes.func,
    clearSelectedFilterConnect: PropTypes.func,
    isHomePage: PropTypes.bool,
    totalAvailableCars: PropTypes.bool,
    saleConfig: PropTypes.bool,
    webPriceDropDesktop: PropTypes.object
};

export default SearchRevamp;
