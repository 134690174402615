import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAutoSuggestions, fetchAutoSuggestionsRobustRanking } from "../../shared/global-search-au/actions";
import SearchRevamp from "./component";
import { clearAllFilters, updateAllSelectedFilterOptions, updateAppliedFilters, updateFilterOptionData, updateSelectedSuggestions, updateUrlOptions, updateListingType, setListingFBEvents, clearSelectedFilter } from "../filters/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import { fetchSearchResult } from "../home/actions";

const mapStateToProps = ({
    user: { secureToken, isLoggedIn },
    filters: {
        recentAppliedFilters,
        allFilters,
        selectedFilters,
        selectedSort,
        urlOptions,
        appliedFilters
    },
    cities: {
        selectedCity
    },
    carListing: {
        searchPageResult
    },
    homeCarList: {
        totalAvailableCars
    },
    config: {
        saleConfig
    },
    abExperiment: {
        webPriceDropDesktop
    }
}) => ({
    secureToken,
    searchPageResult,
    recentAppliedFilters,
    allFilters,
    selectedFilters,
    selectedSort,
    urlOptions,
    appliedFilters,
    isLoggedIn,
    selectedCity,
    totalAvailableCars,
    saleConfig,
    webPriceDropDesktop
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAutoSuggestionsConnect: fetchAutoSuggestions,
    updateFilterOptionDataConnect: updateFilterOptionData,
    fetchSearchResultConnect: fetchSearchResult,
    clearAllFiltersConnect: clearAllFilters,
    reloadCarListConnect: reloadCarList,
    updateUrlOptionsConnect: updateUrlOptions,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    updateAppliedFiltersConnect: updateAppliedFilters,
    updateListingTypeConnect: updateListingType,
    resetCarListConnect: resetCarList,
    updateAllSelectedFilterOptionsConnect: updateAllSelectedFilterOptions,
    setListingFBEventsConnect: setListingFBEvents,
    fetchAutoSuggestionsRobustRankingConnect: fetchAutoSuggestionsRobustRanking,
    clearSelectedFilterConnect: clearSelectedFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchRevamp);
