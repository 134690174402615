import React, { useRef, useCallback } from "react";
import { NUMBER } from "../constants/app-constants";

const useStyleInjection = () => {
    const elementRefs = useRef({});

    const getOrCreateRef = useCallback((key) => {
        if (!elementRefs.current[key]) {
            elementRefs.current[key] = React.createRef();
        }
        return elementRefs.current[key];
    }, []);

    const injectStyles = (key) => {
        const element = elementRefs.current[key]?.current;
        if (!element) {
            return;
        }

        element.style.willChange = "opacity, transform";
        element.style.transform = "translateZ(0)";
        element.style.contain = "layout paint";

        window.requestAnimationFrame(() => {
            element.style.transition = "opacity 0.2s ease, transform 0.2s ease";
            element.style.opacity = "0.5";
            element.style.pointerEvents = "none";
        });

        window.setTimeout(() => {
            element.style.transition = "none";
            element.style.opacity = "1";
            element.style.pointerEvents = "auto";
            element.style.willChange = "auto";
            element.style.transform = "none";
            element.style.contain = "auto";
        }, NUMBER.THIRTY);

        // eslint-disable-next-line consistent-return
        return () => {

            if (element) {
                element.style.transition = "none";
                element.style.opacity = "1";
                element.style.pointerEvents = "auto";
                element.style.willChange = "auto";
                element.style.transform = "none";
                element.style.contain = "auto";
            }

        };

    };

    return {
        getOrCreateRef,
        injectStyles
    };
};

export default useStyleInjection;
